<template>
  <b-card
    :class="cardClasses"
    no-body
  >
    <b-card-body :class="cardBodyClasses">
      <b-card-title
        v-if="hasTitle"
        title-tag="div"
      >
        <subheader
          :additionalClasses="extraTitleClasses"
          :alt-style="altStyle"
          :icon="icon"
          :iconClasses="iconClasses"
          :no-icon="noIcon"
        >
          <template #title>
            {{title}}
          </template>
        </subheader>
      </b-card-title>
      <b-card-sub-title
        v-if="hasSubtitle"
        sub-title-tag="div"
      >
        {{subtitle}}
      </b-card-sub-title>
      <slot name="body"></slot>
      <b-card-text v-if="hasDescription">
        {{description}}
      </b-card-text>
      <b-card-text v-if="hasList" class="card-text__list">
        <div
          v-for="(item, index) in list"
          :key="`${index}_${item}`"
          class="card-text__list__item"
        >
          <div class="card-text__list__item__icon">
            <font-awesome-icon :icon="listIconComputed" :class="listIconClassesComputed"/>
          </div>
          <span>
            {{item}}
          </span>
        </div>
      </b-card-text>
      <b-card-text v-if="hasHeadline" class="card-text__list">
        <div class="headlines">
          <div
            v-for="(item, index) in headlines"
            :key="`${index}_${item}`"
            class="headline"
          >
            <div class="headline__title">{{item.title}}</div>
            <span class="headline__desc">{{item.description}}</span>
          </div>
        </div>
      </b-card-text>
      <div v-if="hasBadge">
        <div>
          <b-badge variant="warning">
            {{badge}}
          </b-badge>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';
import Subheader from '@/components/Subheader.vue';

export default {
  name: 'CardWrapper',
  components: { Subheader },
  props: {
    highlight: Boolean,
    title: String,
    subtitle: String,
    description: String,
    additionalClasses: String,
    altStyle: Boolean,
    extraTitleClasses: String,
    icon: String,
    iconClasses: String,
    noIcon: Boolean,
    list: Array,
    listIcon: String,
    listIconClasses: String,
    headlines: Array,
    noMargin: Boolean,
    badge: String,
    cardBodyClasses: String,
  },
  computed: {
    hasTitle() {
      return !!isNotEmpty(this.title);
    },
    hasSubtitle() {
      return !!isNotEmpty(this.subtitle);
    },
    hasDescription() {
      return !!isNotEmpty(this.description);
    },
    hasList() {
      return !!isNotEmpty(this.list);
    },
    cardClasses() {
      let extraClasses = this.noMargin ? 'mb-0 ' : '';
      if (this.highlight) {
        extraClasses = isNotEmpty(this.additionalClasses) ? `card--highlight ${this.additionalClasses}` : 'card--highlight';
      } else {
        extraClasses = isNotEmpty(this.additionalClasses) ? this.additionalClasses : '';
      }
      return extraClasses;
    },
    // cardTitleClasses() {
    //   const extraClasses = isNotEmpty(this.extraTitleClasses) || '';
    //   // if (this.altStyle) return 'card-title--alt '+extraClasses;
    //   return extraClasses;
    // },
    listIconComputed() {
      if (isPresent(this.listIcon)) return this.listIcon;
      return 'check';
    },
    listIconClassesComputed() {
      if (isNotEmpty(this.listIconClasses)) return this.listIconClasses;
      return 'text-success';
    },
    hasHeadline() {
      return !!isNotEmpty(this.headlines);
    },
    hasBadge() {
      return !!isNotEmpty(this.badge);
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
