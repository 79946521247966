<template>
  <card-wrapper
    :additional-classes="additionalClassesComputed"
    no-icon
    :card-body-classes="cardBodyClasses"
  >
    <template #body>
      <div>
        <div :class="headerClasses" v-if="hasTitle && !ctaCard">
          {{title}}
        </div>
        <div class="card--accent__data" v-if="notAvailable">
          <div class="card--accent__data__cta__subtitle">
            N.D.
          </div>
        </div>
        <div :class="cardDataClasses" v-else>
          <div v-if="valueCard">
            <div class="card--accent__data__spot pt-3">{{ cardValueComputed }}</div>
            <div class="card--accent__data__sub pb-3">
              <font-awesome-icon
                :icon="percentageIcon"
                :class="percentageIconClasses"
              />
              {{cardPercentageComputed}}
            </div>
          </div>
          <div v-if="ctaCard" class="card--accent__data__cta">
            <div v-if="hasTitle" class="card--accent__data__cta__title">
              {{title}}
            </div>
            <div v-if="hasSubtitle" class="card--accent__data__cta__subtitle">
              {{subtitle}}
            </div>
          </div>
          <div v-if="spotCard">
            <div class="card--accent__data__spot">
              <font-awesome-icon
                :icon="spotIcon"
                :class="spotIconClasses"
              />
            </div>
            <div class="card--accent__data__sub">
              {{spotLabel}}
            </div>
          </div>
          <slot name="body" />
        </div>
      </div>
    </template>
  </card-wrapper>
</template>

<script>

import { isPresent } from '@/utils/validators';
import { formatInteger, formatPercent } from '@/utils/formatterHelper';
import CardWrapper from '@/components/clickCard/CardWrapper.vue';

export default {
  name: 'CardAccent',
  components: { CardWrapper },
  props: {
    title: String,
    subtitle: [String, Number],
    additionalClasses: String,
    cardBodyClasses: String,
    valueCard: Boolean,
    cardValue: [String, Number],
    cardPercentage: [String, Number],
    ctaCard: Boolean,
    spotCard: Boolean,
    spotValue: Boolean,
    doubleLineHeader: Boolean,
    notAvailable: Boolean,
    customContentWithTitle: Boolean,
  },
  computed: {
    additionalClassesComputed() {
      if (isPresent(this.additionalClasses)) {
        return `card--accent ${this.additionalClasses}`;
      }
      return 'card--accent';
    },
    hasTitle() {
      return !!isPresent(this.title);
    },
    hasSubtitle() {
      return !!isPresent(this.subtitle);
    },
    percentageIcon() {
      if (this.cardPercentage > 0) return 'arrow-up';
      if (this.cardPercentage < 0) return 'arrow-down';
      return 'equals';
    },
    percentageIconClasses() {
      // if (this.cardPercentage > 0) return 'fa-fw text-success card--accent__icon--scaled-up';
      // if (this.cardPercentage < 0) return 'fa-fw text-danger card--accent__icon--scaled-up';
      return 'fa-fw';
    },
    cardValueComputed() {
      return formatInteger(this.cardValue);
    },
    cardPercentageComputed() {
      const num = Number(this.cardPercentage);
      let val = formatPercent(num);
      // console.log('% check', this.cardPercentage, num, val, this.cardPercentage * -1, formatPercent(this.cardPercentage * -1));
      if (this.cardPercentage > 0) {
        return `+ ${val}`;
      }
      if (this.cardPercentage < 0) {
        val = formatPercent(num * -1);
        // avoid double neg symbol
        return `- ${val}`;
      }
      return val;
    },
    spotIcon() {
      const okIcon = 'circle-check';
      const koIcon = 'triangle-exclamation';
      return this.spotValue ? okIcon : koIcon;
    },
    spotIconClasses() {
      return this.spotValue ? 'fa-fw text-success' : 'fa-fw text-danger';
    },
    spotLabel() {
      const okLabel = 'ASSENTI';
      const koLabel = 'PRESENTI';
      return this.spotValue ? okLabel : koLabel;
    },
    headerClasses() {
      const baseClasses = 'card--accent__header';
      if (this.doubleLineHeader) return `${baseClasses} card--accent__header--double-height`;
      return baseClasses;
    },
    cardDataClasses() {
      if (this.customContentWithTitle) return 'card--accent__data flex-column';
      return 'card--accent__data';
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
