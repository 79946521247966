<template>
  <div class="shadowed-container d-flex align-items-center mb-4">
    <div class="border-right me-2">
      <b-button @click="backToList" variant="link" v-if="!hasHomeButton">
        <font-awesome-icon icon="chevron-left"></font-awesome-icon>
      </b-button>
      <router-link to="/" class="btn btn-link"  v-if="hasHomeButton">
        <font-awesome-icon icon="home"></font-awesome-icon>
      </router-link>
    </div>
    <div>
      <h6 class="m-0">
        <strong>
          {{headerText}}
        </strong>
      </h6>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ButtonLoader',
  props: {
    headerText: String,
    hasHomeButton: Boolean,
  },
  methods: {
    backToList() {
      this.$router.back();
    },
  },
};
</script>
